import * as React from "react"
import HomeVideoBlock from "../../../components/HomeVideoBlock"
import HeroBg from "../../../assets/Hero-section-bg.mp4"

import gsap from "gsap"
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";


const HomeHeroSection = (props) => {
    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        setTimeout(() => {
            let t1 = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
            t1.to("#text-reveal",{y: "0%", marginTop:0, duration:1.8, stagger:0.2,delay: 1.2});

            let block = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
            block.to("#title-block", 1.4, {
              marginTop: 0,
              ease: "Power4.easeInOut",
              delay: 1.3,
              stagger: {
                amount: 0.1
              }
            })

            let tl = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
            tl.from(".line span", 1.3, {
              y: 130,
              ease: "Power4.easeInOut",
              delay: .2,
            //   skewY: 5,
              stagger: {
                amount: 0.1
              }
            })

            let t3 = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
            t3.from(".p-line span", 1.3, {
              y: 130,
              ease: "Power4.easeInOut",
              delay: .5,
            //   skewY: 1,
              stagger: {
                amount: 0.1
              }
            })

            //Mobile responsive
            const homeSectionTitle = document.querySelector(".home-hero-section .section-title .mobile-title");
            const homeSectionDescription = document.querySelector(".home-hero-section .section-description .mobile-description");

            gsap.set(homeSectionTitle, { autoAlpha: 1 });

            const splitSectionTitle = new SplitType(homeSectionTitle, {
                type: "words,lines",
                wordsClass: "words",
                linesClass: "lines",
            })
            const tlSectionTitle = gsap.timeline({});
            tlSectionTitle.from(splitSectionTitle.words,{
                duration: 1.2,
                yPercent: 150,
                ease: "Power4.easeInOut",
                rotationX: 0.5,
                rotationY: 0.5,
                z: 0.5,
                delay: .2,
                stagger: {
                  amount: 0.1
                }
            });

            gsap.set(homeSectionDescription, { autoAlpha: 1 });
            const splitSectionDescription = new SplitType(homeSectionDescription, {
                type: "words,lines",
                wordsClass: "words",
                linesClass: "lines",
            })
            const tlSectionDescription = gsap.timeline({});
            tlSectionDescription.from(splitSectionDescription.words,{
                duration: 1.2,
                yPercent: 150,
                ease: "Power4.easeInOut",
                rotationX: 0.5,
                rotationY: 0.5,
                z: 0.5,
                delay: .4,
                stagger: {
                  amount: 0.1
                }
            });


            
        }, 2200);
    },[])

    return (
        <section className="page-header-section home-hero-section">
            <div className="container">
                <div className="row panel" id="title-block">
                    <div className="col-xl-21 col-lg-21">
                        <h1 className="h1 section-title normal-spacing">
                            <span className="mobile-title">{props.mainTitle}</span>
                            <div className="line">
                                <span>we are strategic <label>design partner</label></span>
                            </div>
                            <div className="line">
                                <span>for saas & tech companies</span>
                            </div>
                        </h1>
                        <p className="section-description">
                            <div className="p-line">
                                <span>Decojent is a UI/UX design agency that takes holistic approach and strives to create exceptional digital</span>
                            </div>
                            <div className="p-line">
                                <span>experiences that align with our client's business strategies.</span>
                            </div>
                            <span className="mobile-description">{props.mainDescription}</span>
                        </p>
                    </div>
                </div>
                <div className="image-block" >
                    <HomeVideoBlock ID={"text-reveal"}/>
                </div>
            </div>
            <div className="hero-video">
                <video 
                    src={HeroBg} 
                    muted 
                    autoPlay 
                    loop
                ></video>
            </div>
        </section>
    )
}

export default HomeHeroSection
